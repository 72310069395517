@keyframes slide-down {
  from {
    transform: translate(0, -40px);
  }
  to {
    transform: translate(0, 0);
  }
}

@keyframes slide-up {
  from {
    transform: translate(0, 40px);
  }
  to {
    transform: translate(0, 0);
  }
}

@keyframes grow {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes scale {
  0% {
    opacity: 0.8;
    transform: scale(1);
  }
  1% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1.06);
  }
}

@keyframes show-down {
  from {
    transform-origin: top center;
    transform: scaleY(0);
  }
  to {
    transform-origin: top center;
    transform: scaleY(1);
  }
}

@keyframes jumpInfinite {
  0% {
    margin-top: 0;
  }
  50% {
    margin-top: 20px;
  }
  100% {
    margin-top: 0;
  }
}
@keyframes pulse {
  50% {
    background-color: rgb(255, 3, 137);
  }
}
@keyframes pulse-green {
  0% {
    transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(19, 236, 62, 1);
  }
  70% {
    transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 243, 61, 0.0);
  }
  100% {
    transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(19, 236, 62, 0.0);
  }
}
@keyframes pulse-blue {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(52, 172, 224, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
  }
  
}