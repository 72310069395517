@font-face {
  font-family: 'Ford Antenna';
  src: url('../assets/fonts/FordAntenna/FordAntenna-Light.otf') format('opentype');
}

@font-face {
  font-family: 'FordAntenna-Medium';
  src: url('../assets/fonts/FordAntenna/FordAntenna-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'FordAntenna-SemiBold';
  src: url('../assets/fonts/FordAntenna/FordAntenna-Semibold.otf') format('opentype');
}

@font-face {
  font-family: 'FordAntenna-Bold';
  src: url('../assets/fonts/FordAntenna/FordAntenna-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'FordAntenna-Regular';
  src: url('../assets/fonts/FordAntenna/FordAntenna-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'FordAntenna-Light';
  src: url('../assets//fonts/FordAntenna/FordAntenna-Light.otf') format('opentype');
}

@font-face {
  font-family: 'Nexa Bold';
  src: url('../assets/fonts/Nexa/Fontfabric - Nexa-Bold.otf') format('opentype');
}
@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto Bold';
  src: url('../assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto Medium';
  src: url('../assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto Black';
  src: url('../assets/fonts/Roboto/Roboto-Black.ttf') format('truetype');
}
body {
  font-family: Ford Antenna "sans-serif";
  margin: 0;

  .Page {
    position: relative;
    min-height: 100vh;
    display: flex;
  }

  h1 {
    font-size: 32px;
    margin: 0;
    font-family: 'Ford Antenna';
    @media screen and (max-width: 767px) {
      font-size: 25px;
    }
  }
  h2 {
    font-size: 20px;
    margin: 0;
    font-family: 'FordAntenna-SemiBold';
    text-align: center;
    @media screen and (max-width: 767px) {
      font-size: 15px;
    }
  }

  p {
    font-size: 14px;
    line-height: 16px;
    margin: 0;
  }

  .FlexBox {
    display: flex;

    &.Vertical {
      flex-direction: column;
    }

    &.Center {
      align-items: center;
      justify-content: center;
    }

    &.AlignCenter {
      align-items: center;
    }

    &.AlignBottom {
      align-items: flex-end;
    }

    &.JustifyCenter {
      justify-content: center;
    }

    &.SpaceBetween {
      justify-content: space-between;
    }
  }

  .Flex {
    flex: 1 1;
  }

  .Bold {
    font-weight: bold;
  }

  .Uppercase {
    text-transform: uppercase;
  }

  .MuiStepIcon-root {
    color: grey;

    &.MuiStepIcon-active, &.MuiStepIcon-completed {
      color: #007DC5; 
    }
  }

  .MuiButton-containedPrimary {
    background-color: #0b1532;
  }

  .MuiCardContent-root {
    padding: 16px !important
  }

  .ConfirmDialog {
    max-width: 500px;
    margin: 0 auto;

    .MuiButton-root {
      text-transform: none;
    }
  }
  
  .MuiButton-containedPrimary.Mui-disabled {
    color: #888;
    background-color: #0005;
  }
}
//.react_slick_slider
.react_slick_slider {
  position: relative;
}
.react_slick_slider .slick-slide {
  margin-right: 15px;
}
.react_slick_slider .slick-prev {
  position: absolute;
  top: 50%;
  left: 5%;
  color: #0b1532;
}
.react_slick_slider .slick-next {
  position: absolute;
  border-radius: 50%;
  padding: 20;
  top: 50%;
  right: 5%;
  color: #0b1532;
}
.react_slick_slider .slick-prev {
  color: #0b1532;
  z-index: 1;
}
.react_slick_slider .slick-next {
  border-radius: 50%;
  padding: 20;
  color: #0b1532;
  z-index: 1;
}
.react_slick_slider .slick-prev:before {
  color: #0b1532;
}
.react_slick_slider .slick-next:before {
  color: #0b1532;
}
.react_slick_slider .slick-prev:hover .slick-next:hover, .slick-next:focus {
  opacity: 1;
  color: #0b1532 !important;
}
//.react_single_slick_slider
.react_single_slick_slider {
  position: relative;
}
.react_single_slick_slider .slick-prev {
  position: absolute;
  top: 50%;
  left: 5%;
  color: white;
}
.react_single_slick_slider .slick-next {
  position: absolute;
  border-radius: 50%;
  padding: 20;
  top: 50%;
  right: 5%;
  color: white;
}
.react_single_slick_slider .slick-prev {
  color: white;
  z-index: 1;
}
.react_single_slick_slider .slick-next {
  border-radius: 50%;
  padding: 20;
  color: white;
  z-index: 1;
}

.react_single_slick_slider .slick-prev:before .slick-next:before {
  color: white;
}
.react_single_slick_slider .slick-prev:hover .slick-next:hover, .slick-next:focus {
  opacity: 1;
  color: white !important;
}

//.react_single_slick_slider_black
.react_single_slick_slider_black {
  position: relative;
}
.react_single_slick_slider_black .slick-prev {
  position: absolute;
  top: 50%;
  left: 5%;
  color: #0b1532 !important;
  border-radius: 50%;
  z-index: 1;
}
.react_single_slick_slider_black .slick-next {
  position: absolute;
  border-radius: 50%;
  padding: 20;
  top: 50%;
  right: 5%;
  color: #0b1532 !important;
  z-index: 1;
}

.react_single_slick_slider_black .slick-prev:before, .react_single_slick_slider_black .slick-next:before {
  color: #0b1532;
}
.react_single_slick_slider_black .slick-prev:hover .slick-next:hover .slick-next:focus {
  opacity: 1;
  color: #0b1532 !important;
}

.react_single_slick_slider_dark {
  position: relative;
}
.react_single_slick_slider_dark .slick-prev {
  position: absolute;
  top: 50%;
  left: 5%;
  color: #0b1532;
  border-radius: 50%;
  z-index: 1;
  // font-size: 40px !important;
}
.react_single_slick_slider_dark .slick-next {
  position: absolute;
  border-radius: 50%;
  padding: 20;
  top: 50%;
  right: 5%;
  color: white;
  z-index: 1;
  // font-size: 40px !important;
}

.react_single_slick_slider_dark, .react_single_slick_slider_dark {
  color: white !important;
  font-size: 40px !important;
}
.react_single_slick_slider_dark {
  color: white !important;
  font-size: 40px !important;
}
.react_single_slick_slider_dark .slick-prev:hover .slick-next:hover .slick-next:focus {
  opacity: 1;
  color: white !important;
  font-size: 40px !important;
}

.image-carousel-uneditedPictures img {
  filter: grayscale(100%);
}